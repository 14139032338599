var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('AnyList',{attrs:{"data":_vm.getDomainList,"loading":_vm.getDomainIsLoading,"filters":[]},on:{"refresh":_vm.onReloadTable},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('article',{staticClass:"media w-50"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[_c('img',{attrs:{"src":"https://bulma.io/images/placeholders/128x128.png"}})])]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[_c('strong',{staticClass:"mr-2"},[_c('a',{attrs:{"href":row.url,"target":"_blank"}},[_vm._v(_vm._s(row.url))])])]),_c('div',[_vm._v(" "+_vm._s(row.webpage_description)+" ")])]),_c('nav',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('a',{staticClass:"level-item"},[_c('span',{staticClass:"icon"},[_c('router-link',{attrs:{"to":{
                      name: 'DomainUpdate',
                      params: {
                        id: row.id,
                      },
                    },"custom":""}},[_c('i',{staticClass:"fas fa-edit"})])],1)])])])]),_c('div',{staticClass:"media-right"},[_c('b-tooltip',{attrs:{"label":String(
                _vm.$t(
                  'modules.customer.domains.list.tooltips.is_ssl_certificate_valid'
                )
              )}},[_c('span',{staticClass:"icon",class:_vm.getDomainSslExpireAtClass(row.ssl_certificate.valid_until)},[_c('i',{staticClass:"fas fa-dot-circle"})])])],1)])]}},{key:"empty",fn:function(){return [_c('b-notification',{attrs:{"closable":false}},[_c('div',{staticClass:"is-fullwidth has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t("modules.customer.domains.list.no_domains_assigned"))+" ")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }