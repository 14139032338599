import { render, staticRenderFns } from "./AnyList.vue?vue&type=template&id=0025dc96&scoped=true&"
import script from "./AnyList.vue?vue&type=script&lang=ts&"
export * from "./AnyList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0025dc96",
  null
  
)

export default component.exports