
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListMixin from "@/mixins/Crud/ListMixin";
import { DomainStoreMixin } from "@/mixins/Stores/DomainStoreMixin";
import { bus } from "@/init/_bus";
import { DOMAINS_WERE_CHECKED } from "@/config/Consts";
import { Customer } from "@/config/Modules";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import AnyList from "@/components/any_list/AnyList.vue";
import IAnyListFilter from "@/components/any_list/interfaces/IAnyListFilter";
import Json from "@/helper/Json";
import { DomainMixin } from "@/mixins/Global/DomainMixin";

@Component({
  components: { AnyList, DefaultTableLayout },
})
export default class DomainList extends mixins(
  DomainMixin,
  DomainStoreMixin,
  ListMixin
) {
  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  protected domainFilters: Array<IAnyListFilter> = [{ field: "domain" }];

  protected get resource(): string {
    return [Customer.resource, String(this.$route.params.id), "domain"].join(
      "/"
    );
  }

  protected loadList(params: any) {
    this.findAllDomainItemsAction(params);
  }

  protected initEventListeners(): void {
    bus.$on(DOMAINS_WERE_CHECKED, () => {
      this.onReloadTable();
    });
  }

  protected init() {
    this.setDomainItem(null);
    // this.setSslCertItem(null);
    this.initEventListeners();
  }
}
